import regex from "@/helpers/regex"

export default class Address {

    constructor(address = null){
        try{
            if(address){
                Object.assign(this, address)
            }else{
                this.buildEmpty()
            }
        }catch(e){
            this.buildEmpty()
        }
    }

    buildEmpty(){
        this.address = ''
        this.city = ''
        this.location = ''
        this.postalCode = ''
        this.country = 'Portugal'
    }

    hasError(){
        return !( 
            this.isValidAddress() &&
            this.isValidCity() &&
            this.isValidLocation() &&
            this.isValidPostal() &&
            this.isValidCountry()
        )
    }

    valid(){
        return {
            address : this.isValidAddress(),
            city : this.isValidCity(),
            location : this.isValidLocation(),
            postalCode : this.isValidPostal(),
            country : this.isValidCountry(),
        }
    }

    isValidAddress(){
        if(!this.address) return false
        const address = this.address.replace(/\s+/g,'');
        if(!address || address.length < 3) return false
        return regex.address.test(address)
    }

    isValidCity(){
        if(!this.city) return false
        const city = this.city.replace(/\s+/g,'');
        if(!city || city.length < 3) return false
        return regex.name.test(city)
    }

    isValidLocation(){
        if(!this.location) return false
        const location = this.location.replace(/\s+/g,'');
        if(!location || location.length < 3) return false
        return regex.name.test(location)
    }

    isValidPostal(){
        if(!this.postalCode) return false
        const postalCode = this.postalCode.replace(/\s+/g,'');
        if(!postalCode || postalCode.length < 3) return false
        return regex.postal.test(postalCode)
    }

    isValidCountry(){
        if(!this.country) return false
        const country = this.country.replace(/\s+/g,'');
        if(!country || country.length < 3) return false
        return regex.name.test(country)
    }
}