<template>
<div>
 <Banner class="hidden-xs" />
  <div class="container-sm mt-4 step-1">
    <div class="col-md-6 mx-auto mt-3">
      <form>
        <h2 class="text-center title-form-step-1">Detalhes da sua viagem</h2>

        <div class="form-row my-2 justify-content-md-center">
          <div class="form-group mt-4 col-md-12">
            <label for="text">Para que países ou regiões pretende viajar?</label>
              <v-select
                multiple
                v-model="insuranceCountry"
                placeholder="Ex: México, Tailandia"
                :options="countryList"
                @input="$v.insuranceCountry.$touch"
                style="background:#e9ecef"
              />
          <div v-if="$v.insuranceCountry.$anyError" class="error">
            <span>Escolha pelo menos um País</span>
          </div>
          </div>

        </div>

        <div class="form-row my-2 justify-content-md-center">
          <div class="form-group col-md-6">
            <label for="date">Data de início</label>
            <!-- <input type="date" v-model="insurance.initialDate" class="form-control" > -->
            <Datepicker 
              :bootstrap-styling="true" 
              v-model="insuranceInitialDate" 
              :disabled-dates="state[0].disabledDatesInitial[0]"
              
              @selected="updateFinalDate()"
              @closed="updateFinalDate()"

            />
          </div>
          <div class="form-group col-md-6">
            <label for="date">Data de fim</label>
            <!-- <input type="date" v-model="insurance.finalDate" class="form-control" > -->
            <Datepicker 
            :bootstrap-styling="true" 
            v-model="insuranceFinalDate" 
            :disabled-dates="state[0].disabledDatesFinal[0]"
            />
          </div>
          <div><small class="text-left">*subscrição máxima do seguro até 270 dias</small></div>
        </div>

        <div class="form-row justify-content-md-center">
          <div class="form-group col-md-12">
            <label for="text" class="form__label">Número de Pessoas</label>
            <b-form-select v-model="insurancePeopleQuantity" :options="peopleQuantityOptions" @input="$v.insurancePeopleQuantity.$touch" @change="updatePeopleAge"></b-form-select>
            <div v-if="$v.insurancePeopleQuantity.$anyError" class="error">
              <span v-if="!$v.insurancePeopleQuantity.required">Campo necessário</span>
              <span v-if="!$v.insurancePeopleQuantity.between">Deve ser entre 1 e 8.</span>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="text" class="form__label" v-if="insurancePeopleQuantity == 1">Qual é a idade do viajante?</label>
            <label for="text" class="form__label" v-else>Quais são as idades dos viajantes?</label>
            <div class="row">
              <div class="col-3" v-for="ip of insurancePeopleQuantity" :key="ip">
                <b-form-input 
                  v-model="personAge[ip-1]" 
                  type="number" 
                  max="100" 
                  min="0" 
                  :placeholder="'Ex. '+Math.floor(Math.random() * (45 - 25 + 1) + 25)" 
                  value=""></b-form-input>
              </div>
            </div>
            <div v-if="agesError" class="error">
              <span v-if="insurancePeopleQuantity == 1">Preencha corretamente a idade do viajante</span>
              <span v-else>Preencha corretamente as idades dos viajantes</span>
            </div>
          </div>
          <div class="col-md-12 text-left">
            <button @click.prevent="getInsurancePrice(),top(),gtag_report_conversion" class="btn btn-danger">Continuar ></button>
          </div>
        </div>
      </form>
    </div>
    </div>
  </div>

</template>

<script>
// https://logaretm.github.io/vee-validate/guide/basics.html#validation-provider
import { required, between } from 'vuelidate/lib/validators'
import Banner from './Banner'
import InsuranceDataService from "../services/InsuranceDataService";
import CountryService from "../services/CountryService";

export default {
  name: "Step1",
  components: {
    Banner,
  },
  computed: {
    insurance: {
      get () {
        return this.$store.state.insurance
      }
    },
    insuranceCountry: {
      get () {
        return this.$store.state.insurance.country
      },
      set (value) {
        this.$store.commit('setInsuranceCountry', {insuranceCountry: value})
      }
    },
    insuranceInitialDate: {
      get () {
        return this.$store.state.insurance.initialDate
      },
      set (value) {
        this.$store.commit('setInsuranceInitialDate', {insuranceInitialDate: value})
      }
    },
    insuranceFinalDate: {
      get () {
        return this.$store.state.insurance.finalDate
      },
      set (value) {
        this.$store.commit('setInsuranceFinalDate', {insuranceFinalDate: value})
      }
    },
    insurancePeopleQuantity: {
      get () {
        return this.$store.state.insurance.peopleQuantity
      },
      set (value) {
        this.$store.commit('setInsurancePeopleQuantity', {insurancePeopleQuantity: value})
      }
    },
    insurancePeople74YearsQuantity: {
      get () {
        return this.$store.state.insurance.people74YearsQuantity
      },
      set (value) {
        this.$store.commit('setInsurancePeople74YearsQuantity', {insurancePeople74YearsQuantity: value})
      }
    },
    insurancePrice: {
      get () {
        return this.$store.state.insurance.price
      },
      set (value) {
        this.$store.commit('setInsurancePrice', {insurancePrice: value.toFixed(2)})
      }
    },
  },
  created(){
    this.$store.commit('clearInsurance',{})
    this.updatePeopleAge()
  },
  updated(){
    var peopleOver74years = 0
    for(let i = 0; i <= this.insurancePeopleQuantity; i++){
      if(this.personAge[i] >= 74){
        peopleOver74years++
      }
    }
    this.insurancePeople74YearsQuantity = peopleOver74years
  },
  data() {
    return {
      countryList: [],
      invalid: false,
      agesError: false,
      personAge: [],
      state: [
        { 
          disabledDatesInitial: [
            {to: new Date() },
          ],
          disabledDatesFinal: [
            { to: new Date( this.$store.state.insurance.initialDate ),
              from: this.getMaxDate( new Date(this.$store.state.insurance.initialDate) ),
            }
          ]
        }
      ],

      peopleQuantityOptions: [
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
      ],
    }
  },
  validations: {
    insurancePeopleQuantity: {
      required,
      between: between(1, 8)
    },
    insuranceCountry: {
      required
    },
  },
  beforeMount(){
    CountryService.list().then( countries =>{
      for(let country of countries.data){
        this.countryList.push(country.name)
      }
    })
  },
  methods: {
    checkPeopleAges(){
      for(let i=0; i < this.personAge.length; i++){
        if(this.personAge[i] === '' || typeof this.personAge[i] === "undefined"){
          this.agesError = true
          return false
        }
      }
      this.agesError = false
      return true
    },

    navigateNext() {
      this.$v.$touch();
      if (this.$v.insurancePeopleQuantity.$anyError === true  || this.$v.insuranceCountry.$anyError === true ||  this.$v.$invalid === true || !this.checkPeopleAges()) {
        return;
      }
      this.$router.push('/planos')
    },
    top() {
      window.scrollTo(0, 0);
    },

    getMaxDate(initialDate){      
      return new Date(initialDate.getFullYear(),initialDate.getMonth(),initialDate.getDate()+270);
    },

    updateFinalDate() {
      let initialDate = new Date(this.$store.state.insurance.initialDate);
      let finalDate   = new Date(this.$store.state.insurance.finalDate);
      let maxDate = this.getMaxDate(initialDate);

      this.state[0].disabledDatesFinal[0].to = this.insuranceInitialDate
      // function to add 270 days to initial data and send to datepicker as "disable from"
      // so days after this date become blocked - font: https://stackoverflow.com/a/13693791/3650719
      
      this.state[0].disabledDatesFinal[0].from = maxDate

      if(finalDate < initialDate)
        this.insuranceFinalDate = initialDate;
      if(finalDate > maxDate)
        this.insuranceFinalDate = maxDate;
    },

    getInsurancePrice() {
      const callback = this.navigateNext
      var data = {
        country: this.insuranceCountry,
        qtdPeople: this.insurancePeopleQuantity,
        startDate: this.insuranceInitialDate,
        endDate: this.insuranceFinalDate,
        qtdPeopleOver74years: this.insurancePeople74YearsQuantity
      }
      InsuranceDataService.getPrice(data)
      .then(response => {
        this.$store.commit('setPromotional', null)
        this.$store.commit('setInsurancePrice', {insurancePrice: response.data})
        callback()
      })
    },

    updatePeopleAge(){
      this.personAge = Array(this.insurancePeopleQuantity)
    }
  }
}
</script>
<style scoped>
  .step-1 {
    margin-top: -90px !important;
    background: #fff;
    padding-top: 20px;
    position: relative;
  }
  .error {
    color: red;
  }
  .title-form-step-1{
    font-size: 26px;
    font-weight: bold;

  }
  @media (max-width:600px)  {
    .step-1 {
      margin-top: 0px !important;
    }
  }
</style>
